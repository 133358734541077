import './scss/index.scss';
// @ts-ignore
import plus from "../../../registry/assets/icon/plus.svg";
import Drop from "../../../personal/components/Drop";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'tach-bar'}>
            <div className="news-box-filter">
                <div className="btn-head-el btn-head-el-mini">
                    <div className="btn btn-create text text-s14"><img src={plus}/> Создать</div>
                </div>
            </div>

            <div className="drop_list_compony">
                <Drop name={'Отдел логистики'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел HR'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел продаж'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел логистики'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел HR'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел продаж'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел логистики'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел HR'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел продаж'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />
                <Drop name={'Отдел логистики'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел HR'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел продаж'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />
                <Drop name={'Отдел логистики'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел HR'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел продаж'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />
                <Drop name={'Отдел логистики'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел HR'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />

                <Drop name={'Отдел продаж'} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение по подбору</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение о приеме на работу</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Положение об отпусках</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Инструкция по ТБ</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Стандарт работы ресепшен</div>
                            </div>
                            <div className="drop-item">
                                <div className="drop-item-name">Дресс-код</div>
                            </div>
                        </div>
                    )
                }}
                />
            </div>
        </div>
    );

}

export default Empty;