import './scss/index.scss';
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";

import contentSvg1 from "./assets/img/Content.svg";
import BoxNav from "../../components/BoxNav";

import svg1 from "./assets/icon/1.svg";
import svg2 from "./assets/icon/2.svg";
import svg3 from "./assets/icon/3.svg";
import svg4 from "./assets/icon/4.svg";
import listSvg from "./assets/img/list.svg";
import tabletwoSvg from "./assets/img/tabletwo.svg";
import biblSvg from "./assets/img/biblia.svg";
import usersSvg from "./assets/img/users.svg";
import plus from "../registry/assets/icon/plus.svg";
import TableCustom from "../../components/TableCustom";
import Drop from "../personal/components/Drop";
import InputMini from "../../components/InputMini";

export default function AboutRoute() {
    const state_in = useSelector(StateSlices);

    // Массив блоков
    const [blocks, setBlocks] = useState([
    ]);

    // Функция для добавления нового блока
    const handleAddBlock = () => {
        setBlocks(prev => [...prev, {id: Date.now(), fileList: [], blockName: '', blockDescription: ''}]);
    };

    // Функция для удаления блока
    const handleDeleteBlock = (id) => {
        setBlocks(prev => prev.filter(block => block.id !== id));
    };

    // Функции для работы с файлами внутри конкретного блока
    const handleFileChange = (event, id) => {
        const newFiles = Array.from(event.target.files);
        setBlocks(prevBlocks =>
            prevBlocks.map(block => {
                if (block.id === id) {
                    return {
                        ...block,
                        fileList: [...block.fileList, ...newFiles]
                    };
                }
                return block;
            })
        );
    };

    const handleFileDelete = (blockId, index) => {
        setBlocks(prevBlocks =>
            prevBlocks.map(block => {
                if (block.id === blockId) {
                    const updatedFiles = block.fileList.filter((_, i) => i !== index);
                    return {
                        ...block,
                        fileList: updatedFiles
                    };
                }
                return block;
            })
        );
    };

    // Обновление названия и описания блока
    const handleBlockNameChange = (id, value) => {
        setBlocks(prevBlocks =>
            prevBlocks.map(block => {
                if (block.id === id) {
                    return {
                        ...block,
                        blockName: value
                    };
                }
                return block;
            })
        );
    };

    const handleBlockDescriptionChange = (id, value) => {
        setBlocks(prevBlocks =>
            prevBlocks.map(block => {
                if (block.id === id) {
                    return {
                        ...block,
                        blockDescription: value
                    };
                }
                return block;
            })
        );
    };

    // Функция формирования заголовка дропа
    const getDropTitle = (block, blockIndex) => {
        const {blockName, blockDescription} = block;
        if (blockName && blockDescription) {
            return `${blockName}: ${blockDescription}`;
        } else if (blockName && !blockDescription) {
            return blockName;
        } else {
            return `Основная часть #${blockIndex + 1}`;
        }
    };

    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Обучение
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Создать курсы
                </p>
            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            Создать курсы
                        </b>
                    </div>
                </div>
            </div>

            <div className="workplace-space">
                <Drop name={'Общие настройки'} edit={false} drop={() => {
                    return (
                        <div className={'task-personal-table'}>
                            <div className="filter-box__inputs">
                                <div className="drop-input-flex">
                                    <InputMini name={'Формат'} placeholder={'Курсы'}/>
                                    <InputMini name={'Длительность '} placeholder={'0'}/>
                                </div>
                                <div className="drop-input-flex">
                                    <InputMini type={'date'} name={'Начало '} placeholder={'Выберите дату '}/>
                                    <InputMini type={'date'} name={'Дедлайн '} placeholder={'Выберите дату '}/>
                                </div>
                                <div className="drop-input-flex">
                                    <InputMini name={'Спикер курса '} placeholder={'ФИО '}/>
                                    <InputMini name={'Слушатели курса '} placeholder={'ФИО '}/>
                                </div>
                            </div>
                        </div>
                    );
                }}/>
            </div>

            <div className="workplace-space workplace-space-big">
                <Drop name={'Основная часть'} edit={false} drop={() => {
                    return (
                        <div className={'task-personal-table'}>
                            <div className="filter-box__inputs">
                                <div className="drop-input-flex" style={{width: "100%"}}>
                                    <InputMini type={'file'} name={'Обложка для курса'} placeholder={''}/>
                                    <InputMini name={'Название курса'} placeholder={''}/>
                                    <InputMini type={'textLarge'} name={'Описание курса'} placeholder={''}/>



                                </div>
                            </div>
                        </div>
                    );
                }}/>
            </div>
            {/** Отображение динамических блоков */}
            {blocks.map((block, blockIndex) => (
                <div key={block.id} className="workplace-space workplace-space-big">
                    <Drop name={getDropTitle(block, blockIndex)} edit={false} drop={() => {
                        return (
                            <div className={'task-personal-table'}>
                                <div className="filter-box__inputs">
                                    <div className="drop-input-flex" style={{width: "100%"}}>
                                        <InputMini
                                            name={'Название блока'}
                                            placeholder={''}
                                            value={block.blockName}
                                            onChange={(e) => handleBlockNameChange(block.id, e.target.value)}
                                        />
                                        <InputMini
                                            type={'textLarge'}
                                            name={'Описание блока'}
                                            placeholder={''}
                                            value={block.blockDescription}
                                            onChange={(e) => handleBlockDescriptionChange(block.id, e.target.value)}
                                        />

                                        {block.fileList.length > 0 && (
                                            <div className="files-list">
                                                {block.fileList.map((file, index) => (
                                                    <div key={index} className="file-item text text-s14">
                                                        <span>{file.name}</span>
                                                        <button
                                                            className="btn-delete-file text text-s14"
                                                            style={{color: "red", marginLeft: "2rem"}}
                                                            onClick={() => handleFileDelete(block.id, index)}
                                                        >
                                                            Удалить
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className="btn-add-files text text-s14">
                                            Добавить файл
                                            <input
                                                className={'file-ab'}
                                                type={'file'}
                                                multiple
                                                onChange={(e) => handleFileChange(e, block.id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginTop: "2rem"}}>
                                    <button
                                        onClick={() => handleDeleteBlock(block.id)}
                                        className="btn-add-files text text-s14"
                                        style={{backgroundColor: "red"}}
                                    >
                                        Удалить блок
                                    </button>
                                </div>
                            </div>
                        );
                    }}/>
                </div>
            ))}

            <div className="center-flex">
                <div onClick={handleAddBlock} className="btn-add-files text text-s14"
                     style={{backgroundColor: "#6418C3", marginTop: "4rem"}}>
                    Добавить блок
                </div>

                <div onClick={() => {
                    alert('Нет доступа')
                }} className="btn-add-files text text-s14" style={{backgroundColor: "#6418C3", marginTop: "1rem"}}>
                    Сохранить
                </div>
                <div onClick={() => {
                    alert('Нет доступа')
                }} className="btn-add-files text text-s14" style={{backgroundColor: "#6418C3", marginTop: "1rem"}}>
                    Создать сертификат
                </div>
            </div>

        </Layout>
    );
}
