import './scss/index.scss';

// @ts-ignore
import searchImg from "./assets/icon/search.svg";

interface Props {
    name?: string;
    placeholder?: string;
    type?: string;
    search?: boolean;
    center?: boolean;
    onChange?: Function;
}

const Empty: React.FC<Props> = ({name, placeholder, type = 'text', onChange, search = false, center = false}) => {


    return (
        <div className={"input " + (search && 'input-search') + (center && ' input-center ')}>
            {
                search && (
                    <div className="icon-search-input" style={{backgroundImage: "url(" + searchImg + ")"}}></div>
                )
            }

            {
                name && (
                    <div className="input-label text text-gray2 text-s14">
                        {name}
                    </div>
                )
            }


            {
                type != 'textLarge' && (
                    <input onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }} type={type} placeholder={placeholder}
                           className={"input-in text text-s14 " + (type == 'file' && 'input-in-file')}/>
                )
            }

            {
                type == 'textLarge' && (
                    <textarea onChange={(e) => {
                        if (onChange) {
                            onChange(e);
                        }
                    }} placeholder={placeholder}
                              className={"input-in text text-s14 "}></textarea>
                )
            }
        </div>
    );

}

export default Empty;