import {
    Routes,
    Route, useNavigate
} from "react-router-dom";


import {useSelector} from "react-redux";
import {getAllDateTime, getBG, getText, getUserInfo} from "../services";
import LangSlices from "../redux/slices/Lang";
import {useEffect} from "react";
import State_in from "../redux/slices/State";
import FirstScreen from "../views/index";
import DashboardScreen from "../views/dashboard";
import CompanyScreen from "../views/company";
import BankScreen from "../views/bank";
import PersonalScreen from "../views/personal";
import RegistryScreen from "../views/registry";
import TimeScreen from "../views/time";
import SettingsScreen from "../views/settings";
import OnboardingScreen from "../views/onboarding";
import WorkplaceOrganizationScreen from "../views/workplaceOrganization";
import PlanOnboardingScreen from "../views/planOnboarding";
import TutorialOnboardingScreen from "../views/tutorialOnboarding";
import TrainingScreen from "../views/training";
import TrainingAddScreen from "../views/training/add";
import IprScreen from "../views/training/ipr";
import PersonalsScreen from "../views/personals";
import EmployeeAbsencesScreen from "../views/employeeAbsences";
import BankPersonalScreen from "../views/personals/bank";
import NaimPersonalScreen from "../views/personals/naim";
import ControlPersonalScreen from "../views/personals/control";




const Router = (() => {

    const lang = useSelector(LangSlices);
    const state_in = useSelector(State_in);

    const navigate = useNavigate();


    useEffect(() => {



    }, []);

    return (
        <Routes>

            <Route path="/" element={<FirstScreen/>}/>
            <Route path="/dashboard" element={<DashboardScreen/>}/>
            <Route path="/company" element={<CompanyScreen/>}/>
            <Route path="/registry" element={<RegistryScreen/>}/>
            <Route path="/personal" element={<PersonalScreen/>}/>
            <Route path="/time" element={<TimeScreen/>}/>
            <Route path="/settings" element={<SettingsScreen/>}/>
            <Route path="/onboarding" element={<OnboardingScreen/>}/>
            <Route path="/workplace" element={<WorkplaceOrganizationScreen/>}/>
            <Route path="/plan_onboarding" element={<PlanOnboardingScreen/>}/>
            <Route path="/tutorial" element={<TutorialOnboardingScreen/>}training/>
            <Route path="/training" element={<TrainingScreen/>}/>
            <Route path="/training/add" element={<TrainingAddScreen/>}/>
            <Route path="/ipr" element={<IprScreen/>}/>
            <Route path="/personals" element={<PersonalsScreen/>}/>
            <Route path="/bank" element={<BankPersonalScreen/>}/>
            <Route path="/naim" element={<NaimPersonalScreen/>}/>
            <Route path="/control" element={<ControlPersonalScreen/>}/>
            <Route path="/employee_absences" element={<EmployeeAbsencesScreen/>}/>

        </Routes>
    )
})
export default Router;
