import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";
import BoxNav from "../../components/BoxNav";
import Org from "./assets/img/org.svg";


import option1Svg from "./assets/icon/option/1.svg";
import option2Svg from "./assets/icon/option/2.svg";
import option3Svg from "./assets/icon/option/3.svg";
import option4Svg from "./assets/icon/option/4.svg";

import Modal from "react-modal";

import create from "./assets/icon/create.svg";
import plus from "./assets/icon/plus.svg";
import plus_create from "./assets/icon/plus_create.svg";
import close from "./assets/icon/close.svg";
import InputMini from "../../components/InputMini";
import TableCustom from "../../components/TableCustom";
import InputCustom from "../../components/inputCustom";
import CheckBox from "../../components/CheckBox";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(2);

    const [modalOpen, setModalOpen] = useState(false);
    const [typeOpen, setTypeOpen] = useState(false);


    const [fio, setFio] = useState('');
    const [dolg, setDolg] = useState('');
    const [ispol, setIspol] = useState('');

    const [addItemNew, setAddItemNew] = useState([
        {
            1: 1,
            2: new Date().toLocaleDateString(),
            3: 'Гульжан',
            4: 'HR',
            5: "HR",
            6: 'Приказ по приему',
            7: "На согласование",
            8: 'Гульжан',
            9: "",
        }
    ]);


    let modal_type_create = [[['Приказ по приему'], ['Приказ по уходу за ребенком'], ['Приказ по переводу'], ['Приказ об отзыве из трудового отпуска'], ['Приказ по увольнению'], ['Приказ по больничному'],], [['Приказ об отпуске'], ['Приказ на изменение зарплаты'], ['Приказ об отпуске без содержания'], ['Трудовой договор'], ['Приказ по беременности и родам'], ['Возврат из отпуска по уходу за ребенком']]];


    let modal_select = {
        "Приказ по приему": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Согласно', 'Выберите статью'], ['Испытательный срок', '0'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Заработная плата', '0'], ['Оклад', '0'], ['Надбавка', '0'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ по переводу": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['С какой должности', 'Выберите должность'], ['На какую должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Изменения зарплаты', '0', 'controls_if'], ['Новая зарплата', '0'], ['Оклад', '0'], ['Надбавка', '0'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ по увольнению": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ об отпуске": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Начало с По', '', 'dates_from_to'], ['Количество дней', '01'], ['Приступить к работе', '01.01.2000'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ об отпуске без содержания": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Начало с По', '', 'dates_from_to'], ['Количество дней', '01'], ['Приступить к работе', '01.01.2000'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ по беременности и родам": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Начало с По', '', 'dates_from_to'], ['Количество дней', '01'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ по уходу за ребенком": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Начало с По', '', 'dates_from_to'], ['Количество дней', '01'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ об отзыве из трудового отпуска": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата отзыва с', '01.01.2000'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ по больничному": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Начало с По', '', 'dates_from_to'], ['Количество дней', '01'], ['Причина больничного', 'Выберите причину'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
        "Приказ на изменение зарплаты": [['Дни', '', 'dates'], ['Согласно статье', 'Выберите статью'], ['ФИО', 'Введите ФИО'], ['Должность', 'Выберите должность'], ['Дата приказа', '01.01.2000'], ['Дата составления приказа', '01.01.2000'], ['Количество дней', '01'], ['Причина больничного', 'Выберите причину'], ['Основание', 'Выберите ФИО'], ['Исполнитель', 'Выберите ФИО'], ['Подписант', 'Выберите ФИО'],],
    };

    return (<Layout headTitle={(<div className={'text-head-box'}>
        <span style={{width: "18.5rem"}}></span>
        <p className={'text  text-gray text-s20'}>
            Главное меню
        </p>
        <span style={{width: "1rem"}}></span>
        <p className={'text  text-gray text-s20'}>
            /
        </p>
        <span style={{width: "1rem"}}></span>
        <p className={'text   text-gray text-s20'}>
            Кадровое администрирование
        </p>
        <span style={{width: "1rem"}}></span>
        <p className={'text  text-gray  text-s20'}>
            /
        </p>
        <span style={{width: "1rem"}}></span>
        <p className={'text text-un text-bly text-s20'}>
            Реестр документов
        </p>
    </div>)}>
        <div className="console console-btns console-mini">
            <div className="console-left">
                <div className="console-name">
                    <b className={'text text-s36'}>
                        Реестр документов
                    </b>
                </div>
            </div>
            <div className="btn-head-el">
                <div className="btn btn-filter text text-s14"><img src={create}/> Фильтр</div>
                <div className="btn btn-create text text-s14" onClick={() => {
                    setTypeOpen(false);
                    setModalOpen(true);
                }}><img src={plus}/> Создать документ
                </div>
            </div>
        </div>


        <Modal
            isOpen={modalOpen}
            onRequestClose={() => {
                setModalOpen(false);
            }}
            contentLabel="Example Modal"
            className={"open-create-document " + (typeOpen && ("open-create-document-type"))}
        >


            <div className={'registry-create'} style={typeOpen ? {width: "37rem"} : {}}>
                <div className={'reg-nav'}>
                    <p onClick={() => {
                        setModalOpen(false);
                    }} className={'text text-s12'} style={{color: "#A5A5A5"}}>
                        Назад
                    </p>
                    <img onClick={() => {
                        setModalOpen(false);
                    }} className={'close-icon'} src={close}/>
                </div>
                {!typeOpen && (<div className="registry-create-type-list">
                    {modal_type_create.map((modal_type_create_map) => {
                        return (<div className={'registry-types'}>
                            {modal_type_create_map.map((modal_type_create_map_map) => {

                                return (<div onClick={() => {
                                    setTypeOpen(modal_type_create_map_map[0]);
                                }} className="registry-type text text-s12">
                                    <b> {modal_type_create_map_map[0]}</b>
                                    <img src={plus_create}/>
                                </div>)
                            })}
                        </div>)
                    })}
                </div>)}


                {typeOpen && (<>
                    {typeOpen in modal_select && modal_select[typeOpen].map((inputModal) => {
                        if (inputModal.length >= 3 && inputModal[2] == 'controls_if') {
                            return (<div className="input false input-center ">
                                <div className="input-label text text-gray2 text-s14">
                                    {inputModal[0]}
                                </div>
                                <div className={'input-box'}>
                                    <div className="check-box-modal">
                                        <CheckBox active={false}/>
                                        <p className={'text text-s12'}>
                                            Да
                                        </p>
                                    </div>
                                    <div className="check-box-modal">
                                        <CheckBox active={false}/>
                                        <p className={'text text-s12'}>
                                            Нет
                                        </p>
                                    </div>
                                </div>
                            </div>);
                        }
                        if (inputModal.length >= 3 && inputModal[2] == 'dates') {
                            return (<div className={'inputs-dates'}>
                                <select className={'select-dates text text-s12'}>
                                    <option value="">День</option>
                                </select>

                                <select className={'select-dates text text-s12'}>
                                    <option value="">Месяц</option>
                                </select>

                                <select className={'select-dates text text-s12'}>
                                    <option value="">Год</option>
                                </select>
                            </div>);
                        }

                        if (inputModal.length >= 3 && inputModal[2] == 'dates_from_to') {
                            return (<div className={'inputs-dates inputs-dates-controls'}>
                                <InputMini type={'date'} name={'Начало с'} placeholder={'01.01.2000'}/>
                                <InputMini type={'date'} name={'По'} placeholder={'01.01.2000'}/>
                            </div>);
                        }

                        return (<InputMini onChange={(e) => {
                            if (inputModal[0] == 'ФИО') {

                                setFio(e.target.value);
                            }
                            if (inputModal[0] == 'Должность') {
                                setDolg(e.target.value);
                            }

                            if (inputModal[0] == 'Исполнитель') {
                                setIspol(e.target.value);
                            }


                        }} center={true} name={inputModal[0]} placeholder={inputModal[1]}/>)
                    })}
                </>)}

                {typeOpen && (<>
                    <p style={{margin: "1.2rem 0"}}></p>

                    <div className="btn btn-center btn-create text text-s14" onClick={() => {

                        setAddItemNew([...addItemNew, {
                            1: addItemNew.length + 1,
                            2: new Date().toLocaleDateString(),
                            3: fio,
                            4: dolg,
                            5: "HR",
                            6: typeOpen,
                            7: "На согласование",
                            8: ispol,
                            9: "",
                        }])
                    }}>
                        Отправить на подпись
                    </div>
                    {/*<p style={{margin: "0.6rem 0"}}></p>*/}
                    {/*<div className="btn btn-center btn-censel btn-create text text-s14"*/}
                    {/*     onClick={() => {*/}
                    {/*     }}>*/}
                    {/*    Посмотреть приказ*/}
                    {/*</div>*/}
                </>)}

            </div>
        </Modal>


        <div className="filter-box filter-box-register">

            <div className="filter-box__inputs">
                <InputMini name={'Тип документа'} placeholder={'Выберите тип документа '}/>
                <InputMini name={'ФИО сотрудника'} placeholder={'ФИО'}/>
                <InputMini name={'По статусу'} placeholder={'Выберите статус'}/>
                <InputMini name={'Дата начала'} placeholder={'Выберите дату '}/>
            </div>
            <div className="btn-head-el">
                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                <div className="btn btn-create text text-s14"> Применить</div>
            </div>

        </div>


        <p style={{margin: "3rem 0"}}></p>
        <div className={'box-el-control-rg'}>

            <p className={'text text-s26'}>
                Входящие
            </p>
            <p style={{margin: "2rem 0"}}></p>
            <TableCustom
                whType={'rem'}
                th={{

                    1: {
                        name: 'ID', w: 12, option: null
                    }, 2: {
                        name: 'По компании', w: 17.4, option: null
                    }, 3: {
                        name: 'ФИО сотрудника', w: 17.4, option: null
                    }, 4: {
                        name: 'Должность', w: 17.4, option: null
                    }, 5: {
                        name: 'По подразделению', w: 17.4, option: null
                    }, 6: {
                        name: 'По типу документа', w: 17.4, option: null
                    }, 7: {
                        name: 'Cтатусу', w: 17.4, option: null
                    }, 8: {
                        name: 'По исполнителю', w: 17.4, option: null
                    }, 9: {
                        name: 'Действие', w: 17.4, option: () => {
                            return (<div className={'icon-img-flex-options'}>
                                <img src={option2Svg}/>
                                <img src={option1Svg}/>
                                <img src={option3Svg}/>
                                <img src={option4Svg}/>
                            </div>);
                        }
                    },
                }}
                tr={addItemNew}
            />
        </div>
        <p style={{margin: "4rem 0"}}></p>

        <div className={'box-el-control-rg'}>

            <p className={'text text-s26'}>
                Исходящие
            </p>
            <p style={{margin: "2rem 0"}}></p>
            <TableCustom
                whType={'rem'}
                th={{

                    1: {
                        name: 'ID', w: 12, option: null
                    }, 2: {
                        name: 'По компании', w: 17.4, option: null
                    }, 3: {
                        name: 'ФИО сотрудника', w: 17.4, option: null
                    }, 4: {
                        name: 'Должность', w: 17.4, option: null
                    }, 5: {
                        name: 'По подразделению', w: 17.4, option: null
                    }, 6: {
                        name: 'По типу документа', w: 17.4, option: null
                    }, 7: {
                        name: 'Cтатусу', w: 17.4, option: null
                    }, 8: {
                        name: 'По исполнителю', w: 17.4, option: null
                    }, 9: {
                        name: 'Действие', w: 17.4, option: () => {
                            return (<div className={'icon-img-flex-options'}>
                                <img src={option2Svg}/>
                                <img src={option1Svg}/>
                                <img src={option3Svg}/>
                                <img src={option4Svg}/>
                            </div>);
                        }
                    },
                }}
                tr={addItemNew}
            />
        </div>


    </Layout>);


}